























































































































































































@import "../../../../assets/css/exper/experiDetail";

.addForm .el-form-item:nth-child(2) {
  margin-bottom: 10px;
}
